<template>
  <div>
    <div v-if="showPopup" class="popup-overlay-ar" @click="closePopup">
      <div class="popup-content-ar" @click.stop :class="{ 'popup-disabled': loading }">
        <button class="close-button-ar" @click="closePopup" :disabled="loading">&times;</button>
        <div class="popup-body-ar">
          <div class="popup-text-ar">
            <h2>Register now!</h2>
            <h1>Enjoy discount 35%</h1>
            <div class="pop-card-ar">
              <p>On Immersion packages "6 months"</p>
            </div>
            <p class="pop-desc-ar">The lowest price for the level, the highest educational value, and a discount of up to <span>15%</span> when paying in cash</p>
            <form @submit.prevent="register" class="pop-form-ar" :class="{ 'form-disabled': loading }">
              <div class="inline-group-ar">
                <div class="form-pop-ar">
                  <label for="fullName" class="pop-label-ar">Full Name</label>
                  <input type="text" class="pop-input-ar" id="fullName" placeholder="Full Name" v-model="fullName" 
                  required :disabled="loading" />
                </div>
                <div class="form-pop-ar">
                  <label for="phoneNumber" class="pop-label-ar">Phone Number</label>
                  <vue-tel-input 
                    class="pop-input-ar" 
                    v-model:phoneNumber="phoneNumber" 
                    v-model:country="country" 
                    @input="onPhoneInput" 
                    @validate="onPhoneValidate" 
                    mode="international" 
                    :preferred-countries="['SA', 'KW', 'AE', 'QA', 'EG', 'JO', 'OM', 'YE', 'BH', 'IQ']" 
                    defaultCountry="SA" 
                    :inputOptions="inputOptions" 
                    :inputprops="{ name: 'phoneNumber', id: 'phoneNumber', required: true, placeholder: 'Phone Number' }" 
                    :disabled="loading" 
                  />
                </div>
              </div>
                <div class="form-pop-ar">
                <label for="email" class="pop-label-ar">Email</label>
                <input type="email" class="pop-input-ar" id="email" placeholder="example@email.com" v-model="email" 
                  required :disabled="loading" />
              </div>
              <input type="hidden" v-model="fullPhoneNumber" />
              <button type="submit" class="pop-btn-ar" :disabled="loading">Register</button>
            </form>
            <div v-if="loading" class="spinner-overlay">
              <div class="spinner"></div>
            </div>
            <p class="pop-bottom-ar">Personal details are kept by monglish_frontend and will only be used in connection with your application. Please read our 
              <a href="/en/terms-and-conditions">terms of use</a> for more information.</p>
          </div>
          <div class="popup-image-ar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import { VueTelInput } from 'vue3-tel-input';
  import 'vue3-tel-input/dist/vue3-tel-input.css';

  export default {
    components: {
      VueTelInput,
    },
    data() {
      return {
        showPopup: false,
        fullName: '',
        email: '',
        phoneNumber: '',
        country: null,
        inputOptions: {
          hiddenInput: true,
        },
        isValidPhone: false,
        fullPhoneNumber: '',
        loading: false,
      };
    },
    mounted() {
      const popupShown = sessionStorage.getItem('popupShown');
      if (!popupShown) {
        this.showPopup = true;
      }
    },
    methods: {
      onPhoneInput(formattedNumber, phoneObject) {
        if (phoneObject && phoneObject.number) {
          console.log('Phone Input:', formattedNumber, phoneObject);
          this.fullPhoneNumber = phoneObject.number;
        }
      },
      onPhoneValidate(isValid, phoneObject) {
        console.log('Validation Result:', isValid);
        this.isValidPhone = isValid;

        if (isValid && phoneObject && phoneObject.number) {
          this.fullPhoneNumber = isValid.number;
        } else if (!isValid) {
          console.warn('Phone number is not valid.');
        }
      },
      closePopup() {
        this.showPopup = false;
        sessionStorage.setItem('popupShown', 'true');
      },
      register() {
        if (!this.fullPhoneNumber) {
          console.error('Phone number is missing or invalid.');
          return;
        }

        // Start loading
        this.loading = true;

        const payload = {
          name: this.fullName,
          email: this.email,
          phone: this.fullPhoneNumber,
        };

        let headers = { 'Content-Type': 'application/json' };
        axios.post(`/api/free-session-forms`, payload, { headers })
          .then(response => {
            const data = response.data;
            if (response.status === 201) {
              console.log('Form submitted successfully:', data);
              this.formSubmitted = true;
              this.closePopup();
            } else {
              console.error('Error in response:', data.message || 'Unknown error');
            }
          })
          .catch(error => {
            console.error('Error submitting form:', error);
          })
          .finally(() => {
            // Stop loading
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
  .popup-overlay-ar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    direction: ltr;
    font-family: 'DIN Next LT Arabic-n', sans-serif;
    overflow: auto;
  }

  .popup-content-ar {
    background: white;
    display: flex;
    max-width: 900px;
    width: 100%;
    max-height: 100%;
    border-radius: 20px;
    position: relative;
  }

  .popup-body-ar {
    display: flex;
    width: 100%;
  }

  .popup-text-ar {
    flex: 1;
    padding: 30px;
    text-align: start;
    line-height: 1.5;
    margin-top: 10px;
  }

  .popup-text-ar h2 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .popup-text-ar h1 {
    font-size: 2em;
    font-weight: 700;
    line-height: 90%;
    color: #ff6f00;
    margin-bottom: 20px;
  }

  .pop-card-ar {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border-radius: 0 25px 25px 0;
    margin-inline-start: -30px;
    padding-inline-start: 30px;
    margin-top: 10px;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  .pop-card-ar p {
    line-height: 90%;
    font-size: 1.75em; /* adjusted to relative unit */
    font-weight: 500;
  }

  .pop-desc-ar {
    line-height: 1.5;
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .pop-desc-ar span {
    color: #ff6f00;
  }

  .popup-image-ar {
    flex: 1;
    background-image: url('@/assets/images/pop.png');
    background-size: cover;
    background-position: center;
    border-radius: 0 20px 20px 0;
    padding: 3.75em; /* adjusted to relative unit */
    width: 100%;
  }

  .close-button-ar {
    position: absolute;
    top: -1.25rem; /* adjusted to relative unit */
    right: 0.625em; /* adjusted to relative unit */
    background: none;
    border: none;
    font-family: 'DIN Next LT Arabic', sans-serif;
    font-size: 3.75em; /* adjusted to relative unit */
    font-weight: 500;
    cursor: pointer;
  }

  form div {
    margin-bottom: 1.25em; /* adjusted to relative unit */
  }

  .pop-form-ar {
    text-align: right;
    direction: ltr;
  }

  .pop-input-ar {
    width: calc(100% - 1.25em); /* adjusted to relative unit */
    padding: 0.625em; /* adjusted to relative unit */
    margin: 0;
    border-radius: 30px;
    background-color: #f3f7ff;
    border: none;
    text-align: left;
  }

  form .form-pop-ar {
    margin-bottom: 20px; /* adjusted to relative unit */
  }

  .pop-label-ar {
    display: block;
    margin-left: 0.75em; /* adjusted to relative unit */
    text-align: left;
    font-weight: 500;
    color: #bababa;
    margin-top: 0; /* adjusted to relative unit */
     /* Consistent spacing */
     margin-bottom: 5px;
    /* Added spacing below label */
  }

  .pop-bottom {
    color: #979797;
    font-family: 'DIN Next LT Arabic-n';
    font-weight: 700;
    margin-top: 20px;
    /* Consistent margin */
  }

  .pop-bottom a {
    color: #165e84;
    text-decoration: underline;
  }

  .inline-group-ar {
    display: flex;
    justify-content: space-between;
  }

  .inline-group-ar .form-group-ar {
    width: 48%;
  }

  .pop-btn-ar {
    background: linear-gradient(45deg, #ff9442, #ff6f00);
    color: white;
    border: none;
    padding: 0.25rem 0.25rem; /* adjusted to relative unit */
    cursor: pointer;
    border-radius: 20px;
    width: 60%;
    margin-inline: 20%;
    font-size: 1.5em; /* adjusted to relative unit */
    font-family: 'DIN Next LT Arabic', sans-serif;
  }

  .pop-bottom-ar {
    color: #979797;
  }

  /* Small screens (max-width: 768px) */
  @media (max-width: 1000px) {
    .popup-overlay {
      align-items: flex-start;
    }

    .popup-content {
      max-width: 90%;
      margin-block: 50px;
    }
    .popup-text-ar {
      padding: 1.5em;
    }
    .popup-text-ar h2 {
      font-size: 1.5em;
    }
    .popup-text-ar h1 {
      font-size: 2.25em;
    }

    .pop-card-ar {
      margin-inline-start: -1.5em;
    }
    .pop-card-ar p {
      font-size: 1.25em;
    }
    .pop-desc-ar {
      font-size: 1.25em;
    }
    .popup-image-ar {
      display: none;
    }
    .close-button-ar {
      top: -35px;
      right: 10px;
    }
    .pop-input-ar {
      width: calc(100% - 1em); /* adjust width for smaller screens */
    }
    .pop-btn-ar {
      padding: 0.75em 1em; /* adjust padding for smaller screens */
      font-size: 1.25em; /* adjust font size for smaller screens */
    }
  }

  .vue-tel-input {
    direction: ltr;
  }

  .vue-tel-input:focus-within {
    box-shadow: unset !important;
  }

  /* loading spinner */
  .popup-disabled {
    pointer-events: none; /* Disable interaction while loading */
  }

  .form-disabled {
    pointer-events: none; /* Disable form interaction while loading */
  }

  .spinner-overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.5); /* Gray background with 0.8 opacity */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure spinner is above everything else */
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-right-color: #ffe100;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  /* loading spinner */
</style>
